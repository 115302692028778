<template>
  <v-container fluid>
    <!-- start of editor -->
    <editor
      :seriesData="seriesArray"
      :defaultSeries="defaultSeries"
      :usersData="allUsersData"
      :branchesData="branchData"
      :docCreate="docCreate"
      :docCopyTo="docCopyTo"
      :setup="setup"
      :tableRows="tableRows"
      :initial="dataItem"
      :tableData="tableData"
      :objectName="objectName"
      :activeObject="activeObject"
      :ExtRef="ExtRef"
      :originNo="originNo"
      :originName="originName"
      :withPayment="withPayment"
      @data="save"
      @postData="postData"
    ></editor>
    <!-- end of editor -->

    <v-row dense>
      <v-col cols="9">
        <!-- <v-btn color="primary" class="right" @click="sendData">Save</v-btn> -->
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          dense
          outlined
          @change="copyDocTo(docId)"
          color="primary"
          v-model="docId"
          :items="copyDocs"
          item-text="name"
          item-value="id"
          label="Copy To"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <!-- snackbar -->
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
import Editor from "../_components/create-editor.vue";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters({
      user: "user",
      outputTaxGroups: "getoutputTaxGroups",
      getDocumentFormSettings: "documentFormSetting/getDocumentFormSettings",
      getDocumentModel: "getDocumentModel",
    }),
    branchData() {
      return this.$store.state.branches;
    },
    allUsersData() {
      return this.$store.state.userdata;
    },
    loggedInUser() {
      return this.$store.state.user;
    },
    useLocalSearch() {
      const userData = this.$store.state.user;
      let localSearchEnable = {};

      // localSearchEnable.false = true;
      localSearchEnable.localurl = null;
      if (userData.oadm.useLocalSearch) {
        localSearchEnable.enable = true;
        localSearchEnable.localurl = userData.oadm.localUrl;
      }
      if (userData.useLocalSearch) {
        localSearchEnable.enable = true;
        localSearchEnable.localurl = userData.oadm.localUrl;
      }

      if (!userData.oadm.useLocalSearch && userData.useLocalSearch) {
        localSearchEnable.enable = true;
        localSearchEnable.localurl = userData.localUrl;
      }
      return localSearchEnable;
    },
  },
  data: () => ({
    dataItem: { parent: false, jdt1: [] },
    docCreate: false,
    docCopyTo: false,
    tenantData: {},
    editMode: false,
    docStatus: null,
    currencies: [],
    setup: {},
    defaultSeries: {},
    branchesArray: [],
    seriesArray: [],
    saleDocs: [],
    copyDocs: [],
    objectName: null,
    docId: null,
    record: {},
    tableRows: [],
    tableData: [],
    activeObject: null,
    ExtRef: null,
    jdt1: [],
    LocTotal: null,
    originNo: null,
    originName: null,
    withPayment: null,
    showEditor: false,
    options: {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer 109877|dPMRAdaMiCqnM1Gai7mAD9D3rd1VXLD495UBXyXE",
      },
    },
  }),
  watch: {
    "$route.params.objectId": {
      handler: "formSettings",
      immediate: true,
    },
    "$route.params.id": {
      handler: "getDocs",
      immediate: true,
    },
  },
  methods: {
    save(data) {
      var isDoc = this.$route.query.isDoc;
      data.ObjType = this.activeObject;
      const activeObject = this.activeObject;
      // const url =
      //   "marketing-doc-update/" +
      //   activeObject +
      //   "/" +
      //   data.id +
      //   `?isDoc=${isDoc}`;
      const url = `documents/?isDoc=${isDoc}`;
      const self = this;
      this.$store
        .dispatch("put", { url, data })
        .then((res) => {
          // const docEntry = res.ResponseData.id;
          // const objectId = res.ResponseData.ObjType;
          this.$store.commit("loader", false);
          if (res.ResultCode == 1200) {
            self.$router.push(`/sales/doc/${activeObject}`);
            //get sap payload
            // this.$store
            //     .dispatch("get", `/integrator/transactions/${objectId}?docEntry=${docEntry}`)
            //     .then((resp) => {
            //       self.postToSap(resp);
            //     })
            //     .catch((err) => {
            //       // self.updateTransferredStatus(objectId,docEntry,"N");
            //       console.log(err);
            //     });
          } else {
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loader", false);
        });
    },
    postData(data) {
      const self = this;
      data.ObjType = this.activeObject;
      data.serviceCallId = this.$route.query.serviceCallId;

      // const formData = new FormData();
      // const files = data.oats || [];

      // if (files.length > 0) {
      //   self.hasAttachments = true;
      //   files.forEach((file, f) => {
      //     const m_file = file.attachmedDetails;
      //     formData.append("files[" + f + "]", m_file);
      //   });
      // }
      // if (self.hasAttachments == true){
      //   this.uploadAttachments(formData,res.ResponseData);
      // }else {
      self.postToSap(data);
      // }
    },
    copyDocTo(id) {
      var isDoc = this.$route.query.isDoc;
      const BaseObject = this.activeObject;
      const docId = this.dataItem.id;
      const copyToDoc = id;
      this.$router.push(
        `/sales/doc/copy/${BaseObject}/${docId}/${copyToDoc}?isDoc=${isDoc}`
      );
    },

    formSettings(objectId) {
      this.$store.commit("loader", true);
      this.activeObject = objectId;
      const self = this;
      this.loading = true;
      if (self.getDocumentFormSettings[objectId]) {
        this.$store.commit("loader", false);
        const documentFormSetting = self.getDocumentFormSettings[objectId];
        self.setup = documentFormSetting;
        self.tableRows = documentFormSetting.tabs[0].tableRows;
        self.seriesArray = documentFormSetting.Series;
        self.usersArray = documentFormSetting.users;
        self.branchesArray = documentFormSetting.branches;
        self.BPLId = documentFormSetting.BPLId;
        self.loading = false;
      } else {
        this.$store
          .dispatch("get", `/form_settings/${objectId}`)
          .then((res) => {
            this.$store.commit("loader", false);
            var newDocumentFormSetting = { ...this.getDocumentFormSetting };
            newDocumentFormSetting[objectId] = res;
            this.$store.commit("documentFormSetting/loadData", newDocumentFormSetting);
            self.setup = res;
            self.tableRows = res.tabs[0].tableRows;
            self.seriesArray = res.Series;
            self.usersArray = res.users;
            self.branchesArray = res.branches;
            self.BPLId = res.BPLId;
            self.loading = false;
          })
          .catch((err) => {
            this.$store.commit("loader", false);
            console.log(err, "error");
          });
      }

      if (this.$route.query.isDoc == 1 || this.$route.query.isDoc == 2) {
        if (self.docStatus !== "C") {
          if (objectId == 13) {
            // set object Name
            this.copyDocs = [{ name: "A/R Credit Memo", id: 14 }];
          }

          if (objectId == 13) {
            this.copyDocs = [{ name: "A/R Credit Memo", id: 14 }];
          }

          if (objectId == 15) {
            this.copyDocs = [];
            this.copyDocs = [
              { name: "Return", id: 16 },
              { name: "A/R Invoice", id: 13 },
            ];
          }

          if (objectId == 17) {
            this.copyDocs = [
              { name: "A/R Invoice", id: 13 },
              { name: "Delivery", id: 15 },
            ];
          }
        }
      }
      // end
      if (this.activeObject !== 13) {
        this.withPayment = "N";
      }
      if (this.activeObject == 13) {
        this.withPayment = "Y";
      }

      // set object name
      if (self.getDocumentModel[objectId]) {
        self.objectName = self.getDocumentModel[objectId].DocumentName;
      } else {
        this.$store
          .dispatch("get", `/doc_model/${objectId}`)
          .then((res) => {
            var newDocumentModel = { ...this.getDocumentModel };
            newDocumentModel[objectId] = res;
            this.$store.commit("setDocumentModel", newDocumentModel);
            self.objectName = res.DocumentName;
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
      }
    },

    postToSap(data) {
      const self = this;
      // const ExternalErpUrl = self.loggedInUser.oadm.ExternalErpUrl+"/documents/"+data.ObjType+"/"+data.id+"?isDoc="+this.$route.query.isDoc;
      const ExternalErpUrl = self.loggedInUser.oadm.ExternalErpUrl + "/documents";
      axios
        .put(ExternalErpUrl, data, this.options)
        .then((resp) => {
          this.$store.commit("loader", false);
          if (resp.data.ResultCode == 1200) {
            //handle attachments
            //handle invoice tims TODO: to be handled later at the moment updating invoice is not Done
            // if (this.activeObject == 13 && resp.data.ResponseData.newObjType != 112){
            //   axios
            //       .post(
            //           self.loggedInUser.oadm.TimsUrl,
            //           resp.data.ResponseData,
            //           this.options
            //       )
            //       .then((timsResp) => {
            //         console.log(timsResp);
            //       });
            // }
          }
          self.$router.push(`/sales/doc/${this.activeObject}?`);
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          self.$refs.snackbar.show(err, "red");
        });
    },

    updateTransferredStatus(ObjType, DocEntry, status) {
      const self = this;
      const url = `sales_doc_update/${ObjType}/${DocEntry}?isDoc=0&status=${status}`;
      this.$store
        .dispatch("post", { url })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          self.updateTransferredStatus(ObjType, DocEntry, "N");
          console.log(err);
        });
    },

    getDocs(id) {
      var isDoc = this.$route.query.isDoc;
      var Status = isDoc == 1 ? 1 : 2;
      var ExtRef = this.$route.query.ExtRef;
      this.$store.commit("loader", true);
      if (this.activeObject !== null) {
        const objectId = this.activeObject;
        if (id) {
          const self = this;
          if (!self.useLocalSearch.enable) {
            this.$store
              .dispatch(
                "get",
                `/documents/${objectId}/${id}?isDoc=${isDoc}&DocStatus=${Status}`
              )
              .then((res) => {
                self.docStatus = res.DocStatus;
                self.dataItem = res;
                self.tableData = res.document_lines;
                // self.originName = res.objecttype.DocumentName;
                self.originNo = res.DocNum;
                // self.showEditor = true;
                this.$store.commit("loader", false);
              })
              .catch((err) => {
                this.$store.commit("loader", false);
                self.$refs.snackbar.show(err, "red");
                console.log(err);
              });
          }
          if (self.useLocalSearch.enable) {
            this.getDocsLocal(objectId, isDoc, ExtRef);
          }
        }
      }
    },
    getDocsLocal(ObjType, isDoc, ExtRef) {
      console.log(isDoc);
      const self = this;
      self.loading = true;
      const localSearchSetting = this.useLocalSearch;
      const url =
        localSearchSetting.localurl +
        `/documents/${ObjType}/0?isDoc=${isDoc}&ExtRef=${ExtRef}`;
      axios.post(url, this.options).then((res) => {
        if (res.data.ResultCode == 1200) {
          self.docStatus = res.data.ResponseData.DocStatus;
          self.dataItem = res.data.ResponseData;
          self.tableData = res.data.ResponseData.document_lines;
          self.originNo = res.data.ResponseData.DocNum;
        }
        self.loading = false;
      });
    },
  },
  mounted() {
    // this.getDocs();
    // this.formSettings();
    console.log(this.useLocalSearch);
  },
};
</script>
